import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import linusfilmer from "./linusfilmer.json";
import linusbild from "./linus.png";

interface Temp {
  temperature?: number;
}

const theme = {
  color: "#2B3E42",
  secondaryColor: "#ffce79",
  background: "#d5e1dd",
};

const Wrapper = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    flex: 0 1 auto;
  }
`;

const Background = styled.div`
  background-color: #d5e1dd;
  min-height: 100vh;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading1 = styled.h1`
  color: ${theme.color};
  font-size: 3rem;

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Heading3 = styled.h3`
  color: ${theme.color};
`;

const Paragraph = styled.p`
  color: ${theme.color};
  width: 100%;
`;

const Button = styled.button`
  background-color: ${theme.secondaryColor};
  border-radius: 6px;
  border: 1px solid #eeb44f;
  /* color: #5c5c5c;
  font-family: arial;
  font-size: 20px;*/
  font-weight: normal;
  padding: 0 2rem;
  cursor: pointer;
`;

const Linus = styled.img<{ active: boolean }>`
  position: absolute;
  max-height: 100%;
  right: ${(props) => (props.active ? 0 : "-100%")};
  transition: all 10s;
`;

// const movies = Array.from(document.querySelectorAll(".lister-item-content"))
// movies.map((x) => {
//   return {
//     link: x.children[0].children.item(1).href,
//     name: x.children[0].innerText.substr(
//       x.children[0].innerText.indexOf(" ") + 1
//     ),
//     rating: x.children[2].innerText.substr(0, x.children[2].innerText.indexOf(" Rate")).trim()
//   };
// });

const prellen = {
  name: "Prästen i Paradiset",
  link: "https://www.imdb.com/title/tt3459004/",
  rating: "4.4",
};

const App: React.FunctionComponent = () => {
  const [movie, setMovie] = React.useState<any>();
  const [linus, setLinus] = React.useState<boolean>(false);
  React.useEffect(() => {}, []);

  const randomMovie = (event: any) => {
    if (event.ctrlKey) {
      setMovie(prellen);
      setLinus(true);
    } else {
      setMovie(linusfilmer[Math.floor(Math.random() * linusfilmer.length)]);
      setLinus(false);
    }
  };

  return (
    <Background>
      <Wrapper>
        <Linus src={linusbild} active={linus} />
        <Heading1>Linusfilmer</Heading1>
        <Heading3>Slumpa fram en Linusfilm™</Heading3>
        <Paragraph>
          Linusfilmer slumpar fram en lagom bra film (med lite action i) som
          ligger i spannet 5.0 - 6.5 på IMDB.
        </Paragraph>
        <br />
        <Button onClick={randomMovie}>
          <Heading3>Ge mig en Linusfilm!</Heading3>
        </Button>
        <Heading1>
          <a href={movie?.link}>{movie?.name}</a>
        </Heading1>
        <Heading1>{movie && `★ ${movie?.rating}`}</Heading1>
      </Wrapper>
    </Background>
  );
};

export default App;
